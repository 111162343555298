import React from "react";
import cx from "classnames";
import { connect } from "react-redux";
import InfoContainer from "./components/info/InfoContainer";
import ImagesContainer from "./components/images/ImagesContainer";
import TipsContainer from "./components/tips/TipsContainer";
import Quiz1 from "./components/quiz/Quiz1";
import Button from "./components/button/Button";
import Wrapper from "./Wrapper";
import Icon from "./ui/icons/Icon";
import jump from "jump.js";

import "normalize.css";
import "./App.scss";
import "./ui/typography.scss";

class App extends React.Component {
  constructor() {
    super();
    let page = "info";
    const path = window.location.pathname;
    if (path.indexOf("tips") > -1) {
      page = "tips";
    } else if (path.indexOf("quiz1") > -1) {
      page = "quiz1";
    } else if (path.indexOf("bilder") > -1) {
      page = "bilder";
    }
    this.state = {
      active: true,
      page,
      loadImages: page === "bilder",
      isScrolling: false,
    };
  }

  onArrowClick = () => {
    jump("#root", {
      offset: -40,
      duration: 400,
      callback: () => {
        this.setState({
          isScrolling: false,
        });
      },
    });
  };

  onClick = () => {
    if (this.state.page === "bilder") {
      this.setState({
        page: "info",
        active: false,
        isScrolling: true,
      });

      setTimeout(() => {
        this.setState({
          active: true,
        });
      }, 300);
    } else {
      this.setState({
        page: "bilder",
        active: false,
        isScrolling: true,
      });

      setTimeout(() => {
        this.setState({
          active: true,
        });
      }, 200);

      setTimeout(() => {
        this.setState({
          loadImages: true,
        });
      }, 300);
    }

    setTimeout(() => {
      this.onArrowClick();
    }, 600);
  };

  render() {
    const appClasses = cx("app", this.state.page, {
      initialized: this.props.initialized,
      "page-active": this.state.active,
      "has-offset": !this.state.isScrolling && this.props.position > 300,
    });

    if (this.state.page === "tips") {
      return (
        <div className={appClasses}>
          <div className="curtain" />
          <div className="page-header">
            <h1>Garhammar</h1>
          </div>
          <div className="page-body">
            <TipsContainer />
          </div>
        </div>
      );
    }

    if (this.state.page === "quiz1") {
      return (
        <div className={appClasses}>
          <div className="curtain" />
          <div className="page-header">
            <h1>Quiz</h1>
          </div>
          <div className="page-body">
            <Quiz1 />
          </div>
        </div>
      );
    }

    return (
      <div className={appClasses}>
        <div className="curtain" />
        <div className="page-header">
          <h1>Garhammar</h1>

          <div className="menu-container">
            <div className="menu">
              <div className="menu-camera">
                <button onClick={this.onClick}>
                  <Icon glyph="CAMERA" />
                </button>
              </div>
              <div className="menu-person">
                <button onClick={this.onClick}>
                  <Icon glyph="PERSON" />
                </button>
              </div>

              <div className="menu-scroll">
                <Button onClick={this.onArrowClick}>
                  <Icon glyph="ARROW_UP" />
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="page-body">
          <Wrapper>
            <ImagesContainer active={this.state.loadImages} />
            <InfoContainer />
          </Wrapper>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  initialized: state.navigation.initialized,
  position: state.navigation.position,
});

export default connect(mapStateToProps)(App);
