import React from "react";
import Section from "../layouts/Section";
import Image from "../../ui/Image/Image";
import Skills from "../skills/Skills";
import Work from "../work/Work";
import Edu from "../edu/Edu";
import Bio from "../bio/Bio";

import "./Info.scss";

const Info = ({ onMouseEnter, onMouseLeave, hovered, isWorkHovered }) => {
  function enterFn(name) {
    return (ev) => {
      onMouseEnter(name, ev);
    };
  }

  return (
    <div className="info-wrapper">
      <div className="left-col">
        <div
          className={
            hovered === "me"
              ? "me-image faded-item active"
              : "me-image faded-item"
          }
          onMouseEnter={enterFn("me")}
          onMouseLeave={onMouseLeave}
        >
          <Section header="Info">
            <Image alt="Staffan Garhammar" src="/images/me1.jpg" ratio={1} />
            <p className="info-name">
              Staffan <i>'consi'</i> Garhammar
              <br />
              staffan.garhammar@gmail.com
            </p>
          </Section>
        </div>

        <div
          className={hovered === "skills" ? "faded-item active" : "faded-item"}
          onMouseEnter={enterFn("skills")}
          onMouseLeave={onMouseLeave}
        >
          <Section header="Skills">
            <Skills />
          </Section>
        </div>

        <div
          className={hovered === "music" ? "faded-item active" : "faded-item"}
          onMouseEnter={enterFn("music")}
          onMouseLeave={onMouseLeave}
        >
          <Section header="Musik">
            <iframe
              title="spotify-embed"
              src="https://open.spotify.com/embed/playlist/4AW9vmKivUpy3HtaYsiCn0"
              frameBorder="0"
              allowtransparency="true"
              allow="encrypted-media"
            ></iframe>
          </Section>
        </div>

        <div
          className={hovered === "edu" ? "faded-item active" : "faded-item"}
          onMouseEnter={enterFn("edu")}
          onMouseLeave={onMouseLeave}
        >
          <Section header="Utbildning">
            <Edu />
          </Section>
        </div>
      </div>

      <div className="right-col">
        <div className="works-wrapper">
          <Section header="Anställningar">
            <Work
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              hovered={hovered}
              isWorkHovered={isWorkHovered}
            />
          </Section>
        </div>
        {/* <div>
          <div className="quotes-wrapper">
            <div
              className={
                hovered === "quotes" ? "faded-item active" : "faded-item"
              }
              onMouseEnter={enterFn("quotes")}
              onMouseLeave={onMouseLeave}
            >
              <Section header="Filosofi">
                <i>
                  &#8220;I want to die at the age of 98. Shot dead by a jealous
                  lover.&#8221;
                </i>
              </Section>
            </div>
          </div>
        </div> */}
      </div>

      <div className="bio-wrapper">
        <div
          className={hovered === "bio" ? "faded-item active" : "faded-item"}
          onMouseEnter={enterFn("bio")}
          onMouseLeave={onMouseLeave}
        >
          <Section header="Biografi">
            <Bio />
          </Section>
        </div>
      </div>
    </div>
  );
};

export default Info;
