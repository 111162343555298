import React from 'react';

const Bio = () => (
    <div className="bio">
        <p>
            <strong>Livet är en</strong> cementtrampolin. Mamma lämnade oss
            strax innan jag föddes. Jag föddes i en timmerstuga som jag själv
            hade byggt. Vi hade inte råd med fyra årstider. Vi hade inte råd med
            wikipedia på vårt internet. När jag masturberade fantiserade jag om
            en ostmacka. Vi hade inte råd med ljus i tunneln. Jag och mina
            syskon fick dela på familjens DNA-stege. Syrrans tama igelkott,
            blind på båda ögonen, klippt i örat, halt på vänster bakben, fick
            dubblera som toalettborste. Han hette Lucky. Vi begravde honom i
            mungipan på grannens Rottweiler. Vaknade jag upp utan erektion på
            julafton så hade jag inget att leka med. Vi köpte sju grundämnen. Vi
            byggde molekyler själva.
        </p>
        <p>
            <strong>Jag hade två fina</strong> syskon. Pappa sa att två av fem
            inte var så tokigt. Vi använde en av tvillingarna för reservdelar.
            Du inser att du är en bit från societén när din tolvåriga syster
            röker vid frukostbordet framför sina egna ungar. Syrran var okej.
            Förväxlade sina p-piller med valium. Fick 14 ungar. Kunde inte bry
            sig mindre.
        </p>
        <p>
            <strong>Hela min familj</strong> arbetade i gruvan. Farfar var
            kontrollant - han sändes ned med avsikt att garantera säkerheten för
            kanariefåglarna. Farfar jobbade där hela sitt liv. Han blev 16 år
            gammal. Farmor var gammal. Hennes personnummer var 3. Hon köpte inte
            längre gröna bananer. Hon hade en trilobit som husdjur. Hennes
            födelseland var Pangea. När hon var ung var Burger King fortfarande
            en prins. Deklarerade på runsten. Förlorade körsbäret till Noak.
            Sjukhuset hade slutat stödja hennes blodgrupp. Hon refererade till
            de puniska krigen. När Alexander Graham Bell uppfann telefonen hade
            han två missade samtal från farmor. Farmor var som en mor till mig.
            Men vi hade inte sex lika ofta.
        </p>
        <p>
            <strong>Vid tre års ålder</strong> tog jag anställning. I gruvan
            arbetade vi åtta dagar i veckan. Vi steg upp en timme innan vi hade
            gått och lagt oss. Vi hade en sju minuters lunch där vi serverades
            varsin käftsmäll. På söndagarna jobbade vi halvdag. Länsman låste då
            upp grindarna redan vid 19.40. Det var hyvens. Bländade av solens
            sista strålar haltade vi barfota hem till barackerna 17 km bort.
            Inte sällan ställde vi då till med ett ordentligt gästabud - bark
            utblandat med bröd och skummande sejdlar fyllda till bredden med
            luft. Euforin fann inga gränser.
        </p>
        <p>
            <strong>Barackerna var trånga.</strong> När grannen masturberade
            fick jag orgasm. Möss fick gå på tå. Min årliga slutlön var
            ingenting vilken betalades ut kvartalsvis. Vi jobbade oss upp från
            extrem fattigdom till att äga ingenting alls. Jag ville bli rik på
            hårt arbete. Men inte mitt eget. Den enklaste vägen att skaffa en
            miljon är att börja med två.
        </p>
        <p>
            <strong>Pappa fick sluta</strong> i gruvan. De genomförde urinprov.
            Pappa skrev sina svar i handen. De fann spår av urin i hans whisky.
            Pappa gillade sin whisky precis som sina kvinnor – tolvåriga och
            tillsammans med kola. Hans lever var ett svart hål. Så svart att
            polisen anklagade den för upplopp. Så utarbetad att den anslöt sig
            till facket. Den jobbade tjugofyra-sju 10 dagar i veckan, hade ingen
            lunch där den serverades en box på koddarna. När alkohol hade
            årsstämma angav den pappa som ett konjunkturberoende. När mamma
            lämnade var pappa förtvivlad - hon tog vinöppnare med sig. Pappa
            gick på whisky-diet - förlorade tre dagar direkt. Han botade
            baksmälla genom att pressa juicen ur en kvarting brännvin. När jag
            själv läste om drogernas effekter blev jag förskräckt. Jag har inte
            läst sedan dess. Alkohol är inte svaret, men den hjälper dig att
            glömma frågan.
        </p>
        <p>
            <strong>1982 slutade pappa</strong> med både sprit och kvinnor. Det
            var de värsta 20 minutrarna i hans liv. Hans nuvarande flickvän sa i
            sängen till honom att han var pervers. Pappa tyckte det var ganska
            stora ord för en nioåring. Han fick göra flygpansljud för oralsex.
            Nioåringar är instabila. Hon kunde banka på dörren i 45 minuter mitt
            i natten. Sedan tröttnade vanligtvis pappa och släppte ut henne. Han
            hade haft otur med båda sina kvinnor, pappa. Den första lämnade
            honom. Den andra stannade kvar.
        </p>
        <p>
            <strong>För att pappa skulle</strong> få ett jobb flyttade familjen.
            Till slut så hittade jag dem. Pappa blev gangster. Pappa var hård.
            Han sov med en kudde under sin pistol. När han skar lök grät löken.
            Han kunde bygga en snögubbe utav regn. Han kunde skära genom kniv
            med varmt smör. Han hatade kokain. Men älskade lukten av det. Vi
            kommer bli tvungna att göra hans kistlock barnsäkert. Pappa visade
            sällan fysisk närhet. Det närmaste jag kom en klapp på axeln var vid
            fyra års ålder då pappa tappade en fläskkotlett i fontanellen på
            mig. Han kände sig smutsig efteråt. Pappa hade få regler -{' '}
            <i>
                'Bli aldrig påkommen i sängen med en död flicka. Eller en
                levande pojke'
            </i>. Pappa brukade säga,{' '}
            <i>
                'Min bäste herre, har du möjligtvis en liten slant över till en
                fattig, olycklig man utan jobb? Allt jag äger är denna pistol'
            </i>. Pappa spenderade sin inkomst på sprit och kvinnor. Resten
            slösade han bort. Luften var ren och sexet smutsigt. Numer verkar
            det omvända gälla.
        </p>
        <p>
            <strong>Pappas släkt var skriande</strong> fattig. Mammas bedövande
            rik. Jag träffade aldrig mamma. Enligt pappa drunknade mamma i en
            önskebrunn. Morfar och mormor var lyckliga i 20 år. Sedan träffades
            de. Förälskelse är ett tillstånd av temporärt vansinne som botas med
            giftermål. Ett bröllop är en begravning där du kan lukta på dina
            egna blommor. Men de höll ihop. Mormor tyckte att människor i rollen
            som makar skulle hålla ihop livet i genom precis som duvor eller
            katoliker.
        </p>
        <p>
            <strong>Mormor var en</strong> hängiven klasskämpe. Hon kämpade för
            ökade klyftor. Mormor kände sig besvärad över att behöva åka i samma
            fordon som sin chaufför. Hon kedjade fast sig vid bordssilvret när
            städerskan kom. Hennes mjukaste personlighetsdrag var ett
            porslinöga. Hon hade ett känsolmässigt djup i paritet med
            brevpapper. Mormor läste bibeln för mig. Den kändes overklig - jag
            menar, en långhårig hippie med tolv vänner? Doktorn förklarade de
            goda nyheterna först. Mormor skulle få en sjukdom uppkallad efter
            sig.
            <i>'Hur länge har jag kvar?'</i>. Doktorn sa <i>'10'</i>.{' '}
            <i>'10 vad? Dagar, månader, år?'</i>. Doktorn sa,{' '}
            <i>'...9, 8, 7...'</i>.
        </p>
        <p>
            <strong>Morfar var mer</strong> förlåtande. Han ansåg att alla hade
            något att bidra med. Även de med mindre hjärnor. Morfar var i
            motsats till mormor en obotlig filantrop. Han förklarade att några
            av arbetarna hade en intelligenskvot som översteg rumstemperatur.
            Ibland tog morfar med en arbetare in till byn för att
            löpnummer-märka den. Det var ett givande alternativ till arbetarens
            vanliga fritidssysselsättningar – promenera planlöst, skjuta katter,
            tatuera in nyckelpigor i svanken, besöka abortkliniken, sutta på
            avgasrör, bestiga sina syskon.
        </p>
        <p>
            <strong>Jag gillade morfar.</strong> Han dog stilla i sin sömn. Så
            vill jag också dö. Inte ångestfullt skrikandes som passagerarna i
            hans bil. Kirurgen mötte mig i korridoren.{' '}
            <i>
                'Det är en otäck smäll. Din morfar är illa däran. Du kommer
                behöva mata honom. Duscha honom'
            </i>. Tårarna började rinna.{' '}
            <i>
                'Du blir tvungen att lägga honom. Följa med honom på toaletten'
            </i>. Jag grät. <i>'Jag bara driver med dig'</i>, sa kirurgen,{' '}
            <i>'han är död'</i>.
        </p>
        <p>
            <strong>Morfar lärde mig</strong> om blommor och bin.{' '}
            <i>
                'Grabben, du vet, sex vi den här åldern är som att spela biljard
                med ett rep. Men jag har haft mitt. Gudarna har välsignat mig
                med många saker i det här livet – intellekt som en jävla
                rymdkirurg, humor som Eddie Murphy och en baguett som en boaorm'
            </i>. Hans tunga kunde ta ett strykjärn till crescendo, så delikat
            att han kunde räkna årsringarna i mormors broskvävnad. Allt handlar
            om mannen i båten. Det enda jag inte kunde prata med morfar om var
            politik - <i>'Morfar, vilket parti röstade du på förra valet?'</i>.{' '}
            <i>
                'Amen, vaa i helvete - du vet ju att det är personligt. Jag
                pratar inte om personliga saker har jag sagt. Hur som helst, jag
                höll på att pirra granfrun i baken när...'
            </i>.
        </p>
        <p>
            <strong>Efter flytten fick</strong> vi börja skolan. Skolan i stan
            var tuff. Jag åkte upp till rektorn för något jag inte gjort - jag
            sprang inte tillräckligt fort. Jag blev orättvist anklagad för att
            ha hävdat att halva lärarkåren var idioter. Jag sa att halva kåren{' '}
            <i>inte</i> var idioter. Rektorn hade ett rättspatos i
            storleksordningen en ekorrpenis. Skolan hävdade att jag hade en
            hjärna som Einstein - död sedan -55. Jag hade problem med
            koncentrationen. Jag hade ingen. Pappa sa att den enda
            bokstavskombination jag inte fått var 'IQ'.
        </p>
        <p>
            <strong>Jag kände mig</strong> som en dömd pedofil som försökte
            försäkra sin glassbil. De flesta pedofiler verkar ha skägg och
            glasögon. Jag kan inte förstå varför barn finner det så sexigt? Enda
            gång jag var intresserad av läraren var när han stod i brand. Bygg
            en man en lägereld och han är varm för en dag. Sätt eld på en man
            och han är varm för resten av sitt liv. Jag var lika förvirrad som
            ett spädbarn i en topless-bar. Jag fick lära mig att en miljon apor
            som bankar på varsitt tangentbord förr eller senare kommer producera
            Shakespeares samlade verk. Nu, tack vare internet, vet vi att det
            inte är sant. Jag fick lite gehör för mina egna funderingar – Hade
            en miljon Shakespeares kunnat skriva som en apa? Varför bar
            kamikaze-piloter hjälm? Personen som uppfann hjulet var en idiot.
            Den som uppfann följande tre måste ha varit ett geni?
            Biologilärarinnan hade en anka. Jag sa{' '}
            <i>'Oj, det var den fulaste gris jag någonsin sett.'</i>. Hon sa,{' '}
            <i>'Gris?! Det är en anka!'</i>.{' '}
            <i>'Det ser jag. Det är honom jag talar med'</i>.
        </p>
        <p>
            <strong>Jag var ingen</strong> teoretiker. Jag var en av dem som var
            tvungen att själv kissa på elstängslet. Jag läste ordboken. Visar
            sig, zebran gjorde det. Jag gillade rymden. Tänker köpa en bok av
            den där rullstolsbundne killen. Jag gillade naturen. Var ofta ute i
            grönskan och letade pornografi. Jag var antagligen dysslikticker.
            Jag gillade de stora antika litterära äventyren med hjältar som
            Klitoris. Jag minns att Dolly, det klonade fåret, själv blev
            befruktad på det traditionella sättet – genom fårahedern.
            <i>
                'Jag fixade byns kyrktak men ingen kallade mig Tim takfixaren.
                Sedan byggde jag byns mur men ingen kallade mig Tim murbyggaren.
                Sedan knullade jag ett får...'
            </i>. Man använder bara 10 % av hjärnan sa de. Vad använder man
            resterande 10 % till?
        </p>
        <p>
            <strong>Jag tog musiklektioner.</strong> Jag sjöng så illa att döva
            vägrade läsa mina läppar. När jag spelade triangel lät det som om
            två skelett kopulerade på ett plåttak. Mitt flöjtspelande liknande
            något en katolsk präst skulle vissla på när han skändar en körpojke.
            Det var mental tortyr som enbart algebra har rätt att tillfoga. Jag
            kunde två låtar. Den ena var introt till 'Gudfadern' och den andra
            var det inte. Cellon var mitt favoritinstrument. Den har ett sådant
            dystert läte. Det låter som någon som läser upp en sista önskan.
        </p>
        <p>
            <strong>Jag blev hänvisad</strong> till en psykolog. Han sa att jag
            var utom allt hopp. Jag sa att jag ville ha ett andra utlåtande. Han
            sa, <i>'Okej, du är ful också'</i>. Han hade antagligen en poäng.
            Jag hade ansikte som en kollapsad lunga. Barnmoskan örfilade mamma
            när jag föddes. Jag föll från det fula trädet och slog i varenda
            gren på vägen ned. Jag hade sexappeal som en skolbussbrand. Pappa
            skämdes när han sålde mig på gatan. Han tyckte vi tog överpris.
        </p>
        <p>
            <strong>En av mina</strong> bröder blev advokat -{' '}
            <i>
                'Säg inget till farmor, hon tror jag spelar piano i ett horhus'
            </i>. En advokat är en människa som är din vän, din förtrogna tills
            dess du har bevisats pank. Jag har aldrig sagt att advokater är
            boskap. Jag har sagt att de skall <i>behandlas</i> som boskap. De
            borde vara mer som albino-noshörningar – sju kvar i Sarengeti, alla
            hanar. Enda skillnaden mellan en överkörd advokat och en överkörd
            skunk är att någon faktiskt försökt väja för skunken. Det tycks vara
            omöjligt att bli dömd för sodomi. Första hälften av nämndemännen
            tror inte att det är fysiskt möjligt och den andra uttövar det.
        </p>
        <p>
            <strong>Jag var min</strong> låtsaskompis fjärdeval. Jag har aldrig
            passat in. Jag befann mig på en nudistbeach i en duffeljacka. Jag
            gillar inte människor. Jag är för kärnvapen. Jag gillar djur.
            Speciellt i en god sås. En fin middag bör inkludera något som har
            dött. Jag ville vara någon. Så här i efterhand inser jag att jag
            borde varit mer specifik. Min enda riktiga vän, var Ogge-bagare. Jag
            visste att han var en polare för han kom upp till mig och sa{' '}
            <i>'Jag är en polare'</i>. Jag vet inte varför Ogge-bagare kallades{' '}
            <i>'Ogge-bagare'</i>. Någon jävla bagare var han inte.
        </p>
        <p>
            <strong>Under skolåren hoppade</strong> mina klasskamrater på allt
            som rörde sig. Men jag såg ingen anledning till att begränsa mig.
            Jag kommer ihåg min första kroppsliga relation. Av hur hennes kropp
            svarade på mina ömma smekningar kunde man nästan tro att hon var vid
            medvetande från toffsarna på fontanellen till taggen runt tån. En
            kyss är en anställningsintervju på högsta våning för ett jobb i
            källaren. Fritzl byggde en källare och höll en flicka inlåst i år.
            Det fick mig att må dåligt – jag är 36 och är inte i närheten av att
            kunna bygga en källare.
        </p>
        <p>
            <strong>Ogge-bagares pappa</strong> tog livet av sig. Vanligtvis
            gillar jag inte folk som tar genvägen hem. Det slog över för honom
            när tåget var försenat. Han kastade sig på spåret. Dog av svält.
            Vissa dagar är det knappt värt att gnaga sig igenom läderrämmarna.
        </p>
        <p>
            <strong>Jag förlorade</strong> oskulden till Ogge-bagares mamma. Hon
            slet av sig sina kläder som en nunna som försakar sina löften. Hon
            var så fet att när hon sjöng var det över för alla. Hon såg ut som
            en strandad val som hade ätit en strandad val. Hennes skugga vägde
            två kilo. Hon hade två små människor i omloppsbana. När hennes
            telefon ringde på stan trodde folk att hon backade. En kannibal hade
            fått andra klassens diabetes av att snaska på henne. Hon var så stor
            att hennes frukosttallrik kom med en livräddare. När hon gick förbi
            TVn så missade jag tre säsonger av Breaking bad. Hon var hälften
            svensk, hälften amerikan och hälften italienare. Jag fick rulla
            henne i mjöl för att hitta den våta fläcken. Men hon luktade gott.
            Det väger upp. Det tog mig tre dagar att bestiga henne. En av mina
            bärare gick vilse. Vi fick sätta upp base camp innan toppförsöket.
            Jag fick använda syrgas under akten. Det var som att kasta in en
            trumpinne i en gymnastiksal. Jag är inte mycket för
            one-night-stands. Jag tycker man skall lära känna en person innan
            man börjar utnyttja och förnedra dem.
        </p>
        <p>
            <strong>Jag blev tillsammans</strong> med en fyra år äldre tjej på
            gatan. Jag hade kännt henne från innan dess hon blev oskuld. Hon bar
            kläder så tighta att man kunde se vad hon tänkte. Rumpa som hos en
            tioårig pojke. Hon hade en papegoja. Aset höll inte tyst i två
            sekunder. Fågeln däremot, var schyst. Hon sa efter första träffen,{' '}
            <i>'Jag har haft en fantastisk afton. Men det var inte den här'</i>.
            Hon sa att jag inte hade några problem att få henne att uppnå
            antiklimax. Jag undrar om hon någon gång fick en orgasm under de två
            år vi var tillsammans, eller om hon fejkade den där natten. Förspel
            är som hamburgare – tre minuter på varje sida. Hennes
            favoritposition i sängen var rygg mot rygg. Hon ringde och sa{' '}
            <i>'Kan du inte komma över. Det är ingen hemma'</i>. Jag åkte över.
            Mycket riktigt, det var ingen hemma.
        </p>
        <p>
            <strong>Jag lärde mig</strong> att det finns en rad mekaniska ting
            för att stimulera kvinnor sexuellt. Den mest effektiva verkar vara
            en Mercedes 380SL. Jag närvarade vid ett möte för prematur
            ejakulation. Jag gick tidigt. Min flickvän blev gravid. Du är inte
            säker med kondom. Det är en myt – precis som växthuseffekten eller
            kungen. Min kompis hade kondom. Han blev överkörd av en buss. Det
            enda sättet att verkligen ha säker sex är att helt avstå – från att
            dricka.
        </p>
        <p>
            <strong>Mitt sexliv är</strong> numer så uselt att jag kommer ställa
            upp i celebat-världsmästerskapen. Jag möter påven i semi. Jag kommer
            inte ens ihåg vem som blir bunden längre. Sista gången jag var inne
            i en kvinna var när besökte frihetsgudinnan. Jag försökte med
            telefonsex men mitt kön fastande i nummerbrickan. Jag måste sätta
            ögonbindel på min högerhand när jag masturberar. Jag vaxade delfinen
            i bilen häromdagen. Jag kommer inte göra det igen. Taxi-chauffören
            var vansinnig. Det verkar finnas två ämnen som damtidningarna
            fokuserar på: 1. <i>'Varför män är svin'</i>. 2.{' '}
            <i>'Hur du attraherar män'</i>. Men vad hade världen varit utan män?
            En fridfull plats med glada, tjocka kvinnor? Sexkanalerna på TV gör
            oss inte till bättre människor, de vidgar inte våra horisonter och
            de sänds inte i tillräckligt hög upplösning. Sex är det vackraste
            och mest naturliga som pengar kan köpa. Jag skulle vilja vara den
            sista mannen på jorden, om inte annat så bara för att se om de där
            tjejerna ljög för mig.
        </p>
        <p>
            <strong>Jag hade inte</strong> velat vara bisexuell. Jag hade inte
            stått ut med att bli nobbad av båda könen. De säger att sex mellan
            en man och en kvinna kan vara fantastiskt. Men jag tänker att det
            måste bli trångt. Homofobi är den irrationella rädslan att tre
            filurer bryter sig in mitt i natten och dekorerar om. Om
            Michelangelo varit hetero så hade det sixtinska kapellet varit målat
            vitt med en roller.
        </p>
        <p>
            <strong>Pappa förstod att</strong> skolan inte var för mig. När jag
            fyllde nio tog pappa med mig för att cappa en golare. Om du önskar
            dig en katt be dina föräldrar om en elefant. Jag fick en kniv. Pappa
            la sin arm om mig. Han sa,{' '}
            <i>
                'Grabben, Charlie, var det? Du vet lådan där jag förvarar
                gräset? Under pistolen och tidningarna med de nakna flickorna,
                där ligger det något till dig'
            </i>.
        </p>
        <p>
            <strong>Det hade blivit</strong> vinter. Det var så kallt att jag
            såg en politiker med händerna i sina egna fickor. Det var så kallt
            att blottare fick beskriva sina delar muntligt. Samma år hade jag
            missat stora delar av den svenska sommaren – jag var i duschen.
            Svensk sommar – börjar i mitten på juli, slutar 45 minuter senare.
            Väderprognosen för natten – mörk. Pappa satt gränsle över golaren
            och vevade med stålröret. Golaren var hal som en fuktig ål i
            maskinfett. Pappa var upptagen som en enarmad taxi-chaufför med
            flatlöss i en New York-korsning. Det blev kladdigt. Pappa sa,{' '}
            <i>
                'Du behöver inte alltid simma snabbare än hajen. Du behöver bara
                simma snabbare än den fete killen'
            </i>. Det hela påminde mycket om att älska med min flickvän - 10%
            upphetsning, 90% ångest.
        </p>
    </div>
);

export default Bio;
