import React from "react";
import cx from "classnames";
import Info from "./Info";

class InfoContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      hovered: false,
    };
  }

  onMouseEnter = (name, ev) => {
    if (name.indexOf("work") === -1) {
      this.setState({
        hovered: name,
      });
      return;
    }

    let tag = "work";

    const parent = window.garhammar.getParentByClass(ev.target, "work-item");

    if (parent) {
      tag = parent.id;
    }

    this.setState({
      hovered: tag,
    });
  };

  onMouseLeave = () => {
    this.setState({
      hovered: false,
    });
  };

  render() {
    const { hovered } = this.state;

    function isWorkHovered(type) {
      return (
        type === "average" ||
        type === "lysa" ||
        type === "viaplay" ||
        type === "dn" ||
        type === "avanza" ||
        type === "tactel"
      );
    }

    const worksHovered = isWorkHovered(hovered);
    const classes = cx("info-container", {
      "is-hovered": !!hovered,
      "is-work-hovered": worksHovered,
    });

    return (
      <div className={classes}>
        <Info
          hovered={hovered}
          isWorkHovered={worksHovered}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
        />
      </div>
    );
  }
}

export default InfoContainer;
