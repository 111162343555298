import React from "react";
import cx from "classnames";
import Button from "../button/Button";
import Icon from "../../ui/icons/Icon";

import "./Tips.scss";

const Tips = (props) => {
  const {
    onPost,
    onMessageChange,
    onNameChange,
    posts,
    name,
    message,
    setRef,
    setPlaceholderRef,
    fixed,
    toggle,
    toggled,
  } = props;

  function formatDate(date) {
    const d = new Date(date);
    let minutes = d.getMinutes();
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    return (
      <span>
        <span className="time">{d.getHours() + ":" + minutes}</span> -{" "}
        {d.toISOString().slice(0, 10)}
      </span>
    );
  }

  return (
    <div className="tips-page">
      <button onClick={toggle} className={cx("show-table-link", { toggled })}>
        Visa tabell
      </button>

      <div ref={setPlaceholderRef} />
      <div className={cx("tips-table", { fixed, toggled })}>
        <ul className="table-header" ref={setRef}>
          <li>
            <button onClick={toggle}>Dölj tabell</button>
          </li>
          <li className="cell-player">
            <span>Donnie</span>
          </li>
          <li className="cell-player">
            <span>Kakel</span>
          </li>
          <li className="cell-player">
            <span>Zege</span>
          </li>
          <li className="cell-player">
            <span>Stumpan</span>
          </li>
          <li className="cell-player">
            <span>Knox</span>
          </li>
          <li className="cell-player">
            <span>Consi</span>
          </li>
        </ul>

        <ul className="table-body">
          <li>
            <span className="cell-title">
              <span className="cell-date">14 juni: 17.00</span>
              <span className="cell-game">Ryssland - Saudiarabien</span>
            </span>
          </li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-miss">X</li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-hit">1</li>
          <li>
            <span className="cell-title">
              <span className="cell-date">15 juni: 14.00</span>
              <span className="cell-game">Egypten - Uruguay</span>
            </span>
          </li>
          <li className="cell-status cell-hit">2</li>
          <li className="cell-status cell-miss">X</li>
          <li className="cell-status cell-hit">2</li>
          <li className="cell-status cell-hit">2</li>
          <li className="cell-status cell-miss">X</li>
          <li className="cell-status cell-hit">2</li>
          <li>
            <span className="cell-title">
              <span className="cell-date">15 juni: 17.00</span>
              <span className="cell-game">Marocko - Iran</span>
            </span>
          </li>
          <li className="cell-status cell-miss">X</li>
          <li className="cell-status cell-miss">1</li>
          <li className="cell-status cell-miss">1</li>
          <li className="cell-status cell-miss">1</li>
          <li className="cell-status cell-miss">X</li>
          <li className="cell-status cell-miss">X</li>
          <li>
            <span className="cell-title">
              <span className="cell-date">15 juni: 20.00</span>
              <span className="cell-game">Portugal - Spanien</span>
            </span>
          </li>
          <li className="cell-status cell-hit">X</li>
          <li className="cell-status cell-miss">2</li>
          <li className="cell-status cell-hit">X</li>
          <li className="cell-status cell-miss">2</li>
          <li className="cell-status cell-hit">X</li>
          <li className="cell-status cell-hit">X</li>
          <li>
            <span className="cell-title">
              <span className="cell-date">16 juni: 12.00</span>
              <span className="cell-game">Frankrike - Australien</span>
            </span>
          </li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-hit">1</li>
          <li>
            <span className="cell-title">
              <span className="cell-date">16 juni: 15.00</span>
              <span className="cell-game">Argentina - Island</span>
            </span>
          </li>
          <li className="cell-status cell-miss">1</li>
          <li className="cell-status cell-miss">1</li>
          <li className="cell-status cell-miss">1</li>
          <li className="cell-status cell-miss">1</li>
          <li className="cell-status cell-miss">1</li>
          <li className="cell-status cell-miss">1</li>
          <li>
            <span className="cell-title">
              <span className="cell-date">16 juni: 18.00</span>
              <span className="cell-game">Peru - Danmark</span>
            </span>
          </li>
          <li className="cell-status cell-hit">2</li>
          <li className="cell-status cell-hit">2</li>
          <li className="cell-status cell-hit">2</li>
          <li className="cell-status cell-hit">2</li>
          <li className="cell-status cell-miss">X</li>
          <li className="cell-status cell-hit">2</li>
          <li>
            <span className="cell-title">
              <span className="cell-date">16 juni: 21.00</span>
              <span className="cell-game">Kroatien - Nigeria</span>
            </span>
          </li>
          <li className="cell-status cell-miss">2</li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-miss">X</li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-hit">1</li>
          <li>
            <span className="cell-title">
              <span className="cell-date">17 juni: 14.00</span>
              <span className="cell-game">Costa Rica - Serbien</span>
            </span>
          </li>
          <li className="cell-status cell-hit">2</li>
          <li className="cell-status cell-hit">2</li>
          <li className="cell-status cell-miss">X</li>
          <li className="cell-status cell-hit">2</li>
          <li className="cell-status cell-miss">X</li>
          <li className="cell-status cell-hit">2</li>
          <li>
            <span className="cell-title">
              <span className="cell-date">17 juni: 17.00</span>
              <span className="cell-game">Tyskland - Mexiko</span>
            </span>
          </li>
          <li className="cell-status cell-miss">1</li>
          <li className="cell-status cell-miss">1</li>
          <li className="cell-status cell-miss">1</li>
          <li className="cell-status cell-miss">1</li>
          <li className="cell-status cell-miss">1</li>
          <li className="cell-status cell-miss">1</li>
          <li>
            <span className="cell-title">
              <span className="cell-date">17 juni: 20.00</span>
              <span className="cell-game">Brasilien - Schweiz</span>
            </span>
          </li>
          <li className="cell-status cell-miss">1</li>
          <li className="cell-status cell-miss">1</li>
          <li className="cell-status cell-miss">1</li>
          <li className="cell-status cell-miss">1</li>
          <li className="cell-status cell-miss">1</li>
          <li className="cell-status cell-miss">1</li>
          <li>
            <span className="cell-title">
              <span className="cell-date">18 juni: 14.00</span>
              <span className="cell-game">Sverige - Sydkorea</span>
            </span>
          </li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-miss">X</li>
          <li className="cell-status cell-miss">X</li>
          <li>
            <span className="cell-title">
              <span className="cell-date">18 juni: 17.00</span>
              <span className="cell-game">Belgien - Panama</span>
            </span>
          </li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-hit">1</li>
          <li>
            <span className="cell-title">
              <span className="cell-date">18 juni: 20.00</span>
              <span className="cell-game">Tunisien - England</span>
            </span>
          </li>
          <li className="cell-status cell-miss">X</li>
          <li className="cell-status cell-hit">2</li>
          <li className="cell-status cell-hit">2</li>
          <li className="cell-status cell-hit">2</li>
          <li className="cell-status cell-hit">2</li>
          <li className="cell-status cell-hit">2</li>
          <li>
            <span className="cell-title">
              <span className="cell-date">19 juni: 14.00</span>
              <span className="cell-game">Colombia - Japan</span>
            </span>
          </li>
          <li className="cell-status cell-miss">X</li>
          <li className="cell-status cell-miss">1</li>
          <li className="cell-status cell-miss">1</li>
          <li className="cell-status cell-miss">1</li>
          <li className="cell-status cell-miss">X</li>
          <li className="cell-status cell-miss">1</li>
          <li>
            <span className="cell-title">
              <span className="cell-date">19 juni: 17.00</span>
              <span className="cell-game">Polen - Senegal</span>
            </span>
          </li>
          <li className="cell-status cell-miss">1</li>
          <li className="cell-status cell-miss">X</li>
          <li className="cell-status cell-miss">X</li>
          <li className="cell-status cell-miss">1</li>
          <li className="cell-status cell-miss">1</li>
          <li className="cell-status cell-miss">1</li>
          <li>
            <span className="cell-title">
              <span className="cell-date">19 juni: 20.00</span>
              <span className="cell-game">Ryssland - Egypten</span>
            </span>
          </li>
          <li className="cell-status cell-miss">2</li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-miss">X</li>
          <li className="cell-status cell-miss">X</li>
          <li className="cell-status cell-miss">2</li>
          <li className="cell-status cell-hit">1</li>
          <li>
            <span className="cell-title">
              <span className="cell-date">20 juni: 14.00</span>
              <span className="cell-game">Portugal - Marocko</span>
            </span>
          </li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-miss">X</li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-hit">1</li>
          <li>
            <span className="cell-title">
              <span className="cell-date">20 juni: 17.00</span>
              <span className="cell-game">Uruguay - Saudiarabien</span>
            </span>
          </li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-hit">1</li>
          <li>
            <span className="cell-title">
              <span className="cell-date">20 juni: 20.00</span>
              <span className="cell-game">Iran - Spanien</span>
            </span>
          </li>
          <li className="cell-status cell-hit">2</li>
          <li className="cell-status cell-hit">2</li>
          <li className="cell-status cell-hit">2</li>
          <li className="cell-status cell-hit">2</li>
          <li className="cell-status cell-hit">2</li>
          <li className="cell-status cell-hit">2</li>
          <li>
            <span className="cell-title">
              <span className="cell-date">21 juni: 14.00</span>
              <span className="cell-game">Danmark - Australien</span>
            </span>
          </li>
          <li className="cell-status cell-hit">X</li>
          <li className="cell-status cell-miss">1</li>
          <li className="cell-status cell-miss">1</li>
          <li className="cell-status cell-hit">X</li>
          <li className="cell-status cell-hit">X</li>
          <li className="cell-status cell-miss">1</li>
          <li>
            <span className="cell-title">
              <span className="cell-date">21 juni: 17.00</span>
              <span className="cell-game">Frankrike - Peru</span>
            </span>
          </li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-hit">1</li>
          <li>
            <span className="cell-title">
              <span className="cell-date">21 juni: 20.00</span>
              <span className="cell-game">Argentina - Kroatien</span>
            </span>
          </li>
          <li className="cell-status cell-miss">1</li>
          <li className="cell-status cell-miss">X</li>
          <li className="cell-status cell-miss">X</li>
          <li className="cell-status cell-miss">1</li>
          <li className="cell-status cell-hit">2</li>
          <li className="cell-status cell-miss">X</li>
          <li>
            <span className="cell-title">
              <span className="cell-date">22 juni: 14.00</span>
              <span className="cell-game">Brasilien – Costa Rica</span>
            </span>
          </li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-hit">1</li>
          <li>
            <span className="cell-title">
              <span className="cell-date">22 juni: 17.00</span>
              <span className="cell-game">Nigeria - Island</span>
            </span>
          </li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-miss">X</li>
          <li className="cell-status cell-miss">2</li>
          <li className="cell-status cell-miss">X</li>
          <li className="cell-status cell-miss">2</li>
          <li className="cell-status cell-miss">X</li>
          <li>
            <span className="cell-title">
              <span className="cell-date">22 juni: 20.00</span>
              <span className="cell-game">Serbien - Schweiz</span>
            </span>
          </li>
          <li className="cell-status cell-miss">X</li>
          <li className="cell-status cell-miss">X</li>
          <li className="cell-status cell-miss">X</li>
          <li className="cell-status cell-miss">X</li>
          <li className="cell-status cell-hit">2</li>
          <li className="cell-status cell-hit">2</li>

          <li>
            <span className="cell-title">
              <span className="cell-date">23 juni: 14.00</span>
              <span className="cell-game">Belgien - Tunisien</span>
            </span>
          </li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-hit">1</li>

          <li>
            <span className="cell-title">
              <span className="cell-date">23 juni: 17.00</span>
              <span className="cell-game">Sydkorea - Mexiko</span>
            </span>
          </li>
          <li className="cell-status cell-hit">2</li>
          <li className="cell-status cell-hit">2</li>
          <li className="cell-status cell-miss">X</li>
          <li className="cell-status cell-hit">2</li>
          <li className="cell-status cell-hit">2</li>
          <li className="cell-status cell-hit">2</li>

          <li>
            <span className="cell-title">
              <span className="cell-date">23 juni: 20.00</span>
              <span className="cell-game">Tyskland - Sverige</span>
            </span>
          </li>
          <li className="cell-status cell-miss">X</li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-hit">1</li>

          <li>
            <span className="cell-title">
              <span className="cell-date">24 juni: 14.00</span>
              <span className="cell-game">England - Panama</span>
            </span>
          </li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-hit">1</li>
          <li>
            <span className="cell-title">
              <span className="cell-date">24 juni: 17.00</span>
              <span className="cell-game">Japan - Senegal</span>
            </span>
          </li>
          <li className="cell-status cell-miss">1</li>
          <li className="cell-status cell-miss">1</li>
          <li className="cell-status cell-miss">2</li>
          <li className="cell-status cell-hit">X</li>
          <li className="cell-status cell-miss">2</li>
          <li className="cell-status cell-miss">1</li>
          <li>
            <span className="cell-title">
              <span className="cell-date">24 juni: 20.00</span>
              <span className="cell-game">Polen - Colombia</span>
            </span>
          </li>
          <li className="cell-status cell-miss">X</li>
          <li className="cell-status cell-miss">X</li>
          <li className="cell-status cell-miss">X</li>
          <li className="cell-status cell-miss">X</li>
          <li className="cell-status cell-miss">1</li>
          <li className="cell-status cell-miss">X</li>
          <li>
            <span className="cell-title">
              <span className="cell-date">25 juni: 16.00</span>
              <span className="cell-game">Saudiarabien – Egypten</span>
            </span>
          </li>
          <li className="cell-status cell-miss">2</li>
          <li className="cell-status cell-miss">2</li>
          <li className="cell-status cell-miss">2</li>
          <li className="cell-status cell-miss">2</li>
          <li className="cell-status cell-miss">2</li>
          <li className="cell-status cell-miss">2</li>
          <li>
            <span className="cell-title">
              <span className="cell-date">25 juni: 16.00</span>
              <span className="cell-game">Uruguay - Ryssland</span>
            </span>
          </li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-miss">2</li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-hit">1</li>
          <li className="cell-status cell-miss">X</li>
          <li className="cell-status cell-miss">X</li>
          <li>
            <span className="cell-title">
              <span className="cell-date">25 juni: 20.00</span>
              <span className="cell-game">Spanien - Marocko</span>
            </span>
          </li>
          <li className="cell-status cell-miss">1</li>
          <li className="cell-status cell-miss">1</li>
          <li className="cell-status cell-miss">1</li>
          <li className="cell-status cell-miss">1</li>
          <li className="cell-status cell-miss">1</li>
          <li className="cell-status cell-miss">1</li>
          <li>
            <span className="cell-title">
              <span className="cell-date">25 juni: 20.00</span>
              <span className="cell-game">Iran - Portugal</span>
            </span>
          </li>
          <li className="cell-status cell-hit">X</li>
          <li className="cell-status cell-miss">2</li>
          <li className="cell-status cell-miss">2</li>
          <li className="cell-status cell-miss">2</li>
          <li className="cell-status cell-miss">2</li>
          <li className="cell-status cell-miss">2</li>
          <li>
            <span className="cell-title">
              <span className="cell-date">26 juni: 16.00</span>
              <span className="cell-game">Danmark - Frankrike</span>
            </span>
          </li>
          <li className="cell-status cell-miss">2</li>
          <li className="cell-status cell-miss">2</li>
          <li className="cell-status cell-miss">2</li>
          <li className="cell-status cell-miss">2</li>
          <li className="cell-status cell-miss">2</li>
          <li className="cell-status cell-hit">X</li>
          <li>
            <span className="cell-title">
              <span className="cell-date">26 juni: 16.00</span>
              <span className="cell-game">Australien - Peru</span>
            </span>
          </li>
          <li className="cell-status cell-miss">X</li>
          <li className="cell-status cell-hit">2</li>
          <li className="cell-status cell-miss">X</li>
          <li className="cell-status cell-miss">1</li>
          <li className="cell-status cell-hit">2</li>
          <li className="cell-status cell-hit">2</li>
          <li>
            <span className="cell-title">
              <span className="cell-date">26 juni: 20.00</span>
              <span className="cell-game">Island - Kroatien</span>
            </span>
          </li>
          <li className="cell-status cell-miss">X</li>
          <li className="cell-status cell-hit">2</li>
          <li className="cell-status cell-hit">2</li>
          <li className="cell-status cell-hit">2</li>
          <li className="cell-status cell-hit">2</li>
          <li className="cell-status cell-hit">2</li>
          <li>
            <span className="cell-title">
              <span className="cell-date">26 juni: 20.00</span>
              <span className="cell-game">Nigeria - Argentina</span>
            </span>
          </li>
          <li className="cell-status cell-hit">2</li>
          <li className="cell-status cell-hit">2</li>
          <li className="cell-status cell-hit">2</li>
          <li className="cell-status cell-hit">2</li>
          <li className="cell-status cell-hit">2</li>
          <li className="cell-status cell-hit">2</li>
          <li>
            <span className="cell-title">
              <span className="cell-date">27 juni: 16.00</span>
              <span className="cell-game">Mexiko - Sverige</span>
            </span>
          </li>
          <li className="cell-status cell-hit">2</li>
          <li className="cell-status cell-miss">X</li>
          <li className="cell-status cell-miss">X</li>
          <li className="cell-status cell-miss">1</li>
          <li className="cell-status cell-miss">1</li>
          <li className="cell-status cell-miss">1</li>
          <li>
            <span className="cell-title">
              <span className="cell-date">27 juni: 16.00</span>
              <span className="cell-game">Sydkorea - Tyskland</span>
            </span>
          </li>
          <li className="cell-status cell-miss">2</li>
          <li className="cell-status cell-miss">2</li>
          <li className="cell-status cell-miss">2</li>
          <li className="cell-status cell-miss">2</li>
          <li className="cell-status cell-miss">2</li>
          <li className="cell-status cell-miss">2</li>
          <li>
            <span className="cell-title">
              <span className="cell-date">27 juni: 20.00</span>
              <span className="cell-game">Serbien - Brasilien</span>
            </span>
          </li>
          <li className="cell-status cell-hit">2</li>
          <li className="cell-status cell-hit">2</li>
          <li className="cell-status cell-hit">2</li>
          <li className="cell-status cell-hit">2</li>
          <li className="cell-status cell-hit">2</li>
          <li className="cell-status cell-hit">2</li>
          <li>
            <span className="cell-title">
              <span className="cell-date">27 juni: 20.00</span>
              <span className="cell-game">Schweiz – Costa Rica</span>
            </span>
          </li>
          <li className="cell-status cell-hit">X</li>
          <li className="cell-status cell-miss">1</li>
          <li className="cell-status cell-miss">1</li>
          <li className="cell-status cell-miss">1</li>
          <li className="cell-status cell-hit">X</li>
          <li className="cell-status cell-miss">1</li>
          <li>
            <span className="cell-title">
              <span className="cell-date">28 juni: 16.00</span>
              <span className="cell-game">Japan - Polen</span>
            </span>
          </li>
          <li className="cell-status cell-hit">2</li>
          <li className="cell-status cell-hit">2</li>
          <li className="cell-status cell-hit">2</li>
          <li className="cell-status cell-hit">2</li>
          <li className="cell-status cell-hit">2</li>
          <li className="cell-status cell-hit">2</li>
          <li>
            <span className="cell-title">
              <span className="cell-date">28 juni: 16.00</span>
              <span className="cell-game">Senegal - Colombia</span>
            </span>
          </li>
          <li className="cell-status cell-miss">X</li>
          <li className="cell-status cell-hit">2</li>
          <li className="cell-status cell-miss">1</li>
          <li className="cell-status cell-hit">2</li>
          <li className="cell-status cell-hit">2</li>
          <li className="cell-status cell-hit">2</li>
          <li>
            <span className="cell-title">
              <span className="cell-date">28 juni: 20.00</span>
              <span className="cell-game">Panama - Tunisien</span>
            </span>
          </li>
          <li className="cell-status cell-miss">X</li>
          <li className="cell-status cell-miss">1</li>
          <li className="cell-status cell-miss">X</li>
          <li className="cell-status cell-hit">2</li>
          <li className="cell-status cell-hit">2</li>
          <li className="cell-status cell-miss">X</li>
          <li>
            <span className="cell-title">
              <span className="cell-date">28 juni: 20.00</span>
              <span className="cell-game">England - Belgien</span>
            </span>
          </li>
          <li className="cell-status cell-hit">2</li>
          <li className="cell-status cell-hit">2</li>
          <li className="cell-status cell-miss">X</li>
          <li className="cell-status cell-hit">2</li>
          <li className="cell-status cell-hit">2</li>
          <li className="cell-status cell-hit">2</li>

          <li className="goaler">Farväl till Swea</li>
          <li className="cell-status goaler cell-miss">
            <span>Grupp</span>
          </li>
          <li className="cell-status goaler cell-miss">
            <span>Grupp</span>
          </li>
          <li className="cell-status goaler cell-miss">
            <span>Åttondel</span>
          </li>
          <li className="cell-status goaler cell-miss">
            <span>Grupp</span>
          </li>
          <li className="cell-status goaler cell-miss">
            <span>Grupp</span>
          </li>
          <li className="cell-status goaler cell-miss">
            <span>Grupp</span>
          </li>

          <li className="goaler">Målskytt</li>
          <li className="cell-status goaler cell-miss">
            <span>Jesus</span>
          </li>
          <li className="cell-status goaler cell-miss">
            <span>Neymar</span>
          </li>
          <li className="cell-status goaler cell-miss">
            <span>
              <strong>LUKAKU</strong>
            </span>
          </li>
          <li className="cell-status goaler cell-miss">
            <span>Neymar</span>
          </li>
          <li className="cell-status goaler cell-miss">
            <span>Lukaku</span>
          </li>
          <li className="cell-status goaler cell-miss">
            <span>Agüero</span>
          </li>

          <li className="goaler">Vinnare</li>
          <li className="cell-status goaler cell-miss">
            <span>Brasilien</span>
          </li>
          <li className="cell-status goaler cell-hit">
            <span>Frankrike</span>
          </li>
          <li className="cell-status goaler cell-miss">
            <span>Brasilien</span>
          </li>
          <li className="cell-status goaler cell-miss">
            <span>Spanien</span>
          </li>
          <li className="cell-status goaler cell-miss">
            <span>Belgien</span>
          </li>
          <li className="cell-status goaler cell-miss">
            <span>Tyskland</span>
          </li>

          <li>
            <span className="cell-title">
              <span className="cell-game">Poäng</span>
            </span>
          </li>
          <li className="cell-status cell-miss">24</li>
          <li className="cell-status cell-hit">28</li>
          <li className="cell-status cell-miss">20</li>
          <li className="cell-status cell-miss">27</li>
          <li className="cell-status cell-miss">27</li>
          <li className="cell-status cell-hit">28</li>
        </ul>
      </div>

      <div className="post-comments">
        <form onSubmit={onPost}>
          <input
            type="text"
            name="name"
            placeholder="Namn"
            onChange={onNameChange}
            value={name}
          />

          <div className="relative">
            <textarea
              rows="6"
              name="message"
              placeholder="Meddelande"
              onChange={onMessageChange}
              value={message}
            />

            <Button disabled={!name || !message}>
              <Icon glyph="SEND" />
            </Button>
          </div>
        </form>

        {posts.length === 0 ? null : (
          <ul className="post-list">
            {posts.map((post) => {
              return (
                <li key={post.date}>
                  <div className="post-header">
                    <div className="post-name">{post.name}</div>
                    <div className="post-date">{formatDate(post.date)}</div>
                  </div>
                  <div>{post.message}</div>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Tips;
