import React from 'react';
import Question from './Question';
import Link from '../../ui/buttons/Link';

import './Quiz.scss';

const Quiz1 = () => (
    <section className="quiz">

        <div className="quiz-top">
            17 september 2018
            <i>Funkabo pizzeria</i>
        </div>

        <h3><span>1.</span><span className="subject">Fysik</span></h3>
        <div>
            <Question>
                <h4>a.</h4>
                <span className="quiz-points">1 p.</span>
                <p>
                    Vilket grundämne är det vanligast förekommande i universum?
                </p>
                <div className="quiz-answer">
                    <span>Svar:</span>Väte.<br/>Väte är det "enklaste" grundämnet och har atomnummer 1 (en proton och en neutron i kärnan samt en elektron)

                    <div className="quiz-answer-link">
                        <Link to="https://sv.wikipedia.org/wiki/Grund%C3%A4mnenas_f%C3%B6rekomst">
                            Läs mer
                        </Link>
                    </div>

                </div>
            </Question>

            <Question>
                <h4>b.</h4>
                <span className="quiz-points">1 p.</span>
                <p>
                    Vilket grundämne är det vanligast förekommande i atmosfären?
                </p>
                <div className="quiz-answer">
                    <span>Svar:</span>Kväve.<br/>Atmosfären består till ca 78 % av kväve. Syre står för ca 21 %. Noterbart är även att växthusgasen kodioxid står för ca 0,04 %;
                    <div className="quiz-answer-link">
                        <Link to="https://sv.wikipedia.org/wiki/Jordens_atmosf%C3%A4r">
                            Läs mer
                        </Link>
                    </div>
                </div>
            </Question>


            <Question>
                <h4>c.</h4>
                <span className="quiz-points">1 p.</span>
                <p>
                    Vad heter upphovsmannen till det periodiska systemet?
                </p>
                <div className="quiz-answer">
                    <span>Svar:</span>Dmitrij Mendelejev.<br/>

                    <div className="quiz-answer-link">
                        <Link to="https://sv.wikipedia.org/wiki/Dmitrij_Mendelejev">
                            Läs mer
                        </Link>
                    </div>

                </div>
            </Question>

        </div>

        <h3><span>2.</span><span className="subject">Historia</span></h3>
        <div>

            <Question>
                <h4>a.</h4>
                <span className="quiz-points">1 p.</span>
                <p>
                    Den romerska republiken utkämpade ett flertal krig mot Karthago vars mest kända befälhavare tog sig med elefanter över Alperna i på romerskt territorium. Vad heter deras mest kända befälhavare?
                </p>
                <div className="quiz-answer">
                    <span>Svar:</span>Hannibal Barca.<br/>

                    <div className="quiz-answer-link">
                        <Link to="https://sv.wikipedia.org/wiki/Andra_puniska_kriget">
                            Läs mer
                        </Link>
                    </div>

                </div>
            </Question>

            <Question>
                <h4>b.</h4>
                <span className="quiz-points">1 p.</span>
                <p>
                    Vad kallas dess krig med ett samlingsnamn?
                </p>
                <div className="quiz-answer">
                    <span>Svar:</span>De puniska krigen.<br/>

                    <div className="quiz-answer-link">
                        <Link to="https://sv.wikipedia.org/wiki/Puniska_krigen">
                            Läs mer
                        </Link>
                    </div>

                </div>
            </Question>

            <Question>
                <h4>c.</h4>
                <span className="quiz-points">0.5 p. per svar</span>
                <p>
                    Karthago är beläget i nutida Tunisien. Till vilka två grannländer gränsar Tunisien och var heter deras respektive huvudstäder? (Söker fyra namn)
                </p>
                <div className="quiz-answer">
                    <span>Svar:</span>Algeriet (Alger) samt Libyen (Tripoli)<br/>

                    <div className="quiz-answer-link">
                        <Link to="https://www.google.se/maps/@26.0774984,8.3088008,5z">
                            Läs mer
                        </Link>
                    </div>

                </div>
            </Question>


        </div>



    </section>
);

export default Quiz1;
