import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import ArrowUp from './icons/ArrowUp';
import Camera from './icons/Camera';
import External from './icons/External';
import Person from './icons/Person';
import Send from './icons/Send';
import UnfoldLess from './icons/UnfoldLess';
import UnfoldMore from './icons/UnfoldMore';
import './Icon.scss';

export const glyphs = {
    ARROW_UP: { class: 'icon-arrow-up', icon: ArrowUp },
    CAMERA: { class: 'icon-camera', icon: Camera },
    EXTERNAL: { class: 'icon-external', icon: External },
    PERSON: { class: 'icon-person', icon: Person },
    SEND: { class: 'icon-send', icon: Send },
    UNFOLD_LESS: { class: 'icon-unfold-less', icon: UnfoldLess },
    UNFOLD_MORE: { class: 'icon-unfold-more', icon: UnfoldMore },
};

const Icon = ({ glyph, className }) => {
    const icon = glyphs[glyph];
    const IconElement = icon.icon;
    const classNames = cx('icon-wrapper', className, icon.class);

    return (
        <span className={classNames}>
            <IconElement />
        </span>
    );
};

Icon.propTypes = {
    glyph: PropTypes.string.isRequired,
    className: PropTypes.string,
};

Icon.defaultProps = {
    className: '',
};

export default Icon;
