/*eslint-disable no-script-url*/
import React from 'react';

import './Skills.scss';

const Skills = () => (
    <div className="skills">
        <dl>
            <dt>Sova:</dt>
            <dd>
                <ul>
                    <li className="active" />
                    <li className="active" />
                    <li className="active" />
                    <li className="active" />
                    <li className="active" />
                    <li className="active" />
                    <li className="active" />
                    <li className="active" />
                    <li />
                    <li />
                </ul>
            </dd>

            <dt>Dagdrömma:</dt>
            <dd>
                <ul>
                    <li className="active" />
                    <li className="active" />
                    <li className="active" />
                    <li className="active" />
                    <li className="active" />
                    <li className="active" />
                    <li className="active" />
                    <li />
                    <li />
                    <li />
                </ul>
            </dd>

            <dt>Brygga öl:</dt>
            <dd>
                <ul>
                    <li className="active" />
                    <li />
                    <li />
                    <li />
                    <li />
                    <li />
                    <li />
                    <li />
                    <li />
                    <li />
                </ul>
            </dd>

            <dt>Kosmologi:</dt>
            <dd>
                <ul>
                    <li className="active" />
                    <li className="active" />
                    <li className="active" />
                    <li className="active" />
                    <li className="active" />
                    <li />
                    <li />
                    <li />
                    <li />
                    <li />
                </ul>
            </dd>

            <dt>Javascript:</dt>
            <dd>
                <ul>
                    <li className="active" />
                    <li className="active" />
                    <li className="active" />
                    <li className="active" />
                    <li className="active" />
                    <li className="active" />
                    <li className="active" />
                    <li className="active" />
                    <li />
                    <li />
                </ul>
            </dd>

            <dt>Dricka öl:</dt>
            <dd>
                <ul>
                    <li className="active" />
                    <li className="active" />
                    <li className="active" />
                    <li className="active" />
                    <li className="active" />
                    <li className="active" />
                    <li className="active" />
                    <li className="active" />
                    <li className="active" />
                    <li className="active" />
                </ul>
            </dd>

            <dt>Romerska kejsare:</dt>
            <dd>
                <ul>
                    <li className="active" />
                    <li className="active" />
                    <li className="active" />
                    <li className="active" />
                    <li className="active" />
                    <li className="active" />
                    <li />
                    <li />
                    <li />
                    <li />
                </ul>
            </dd>

            <dt>Kärlek:</dt>
            <dd className="love">- Flatline -</dd>
        </dl>
    </div>
);

export default Skills;
