import React from 'react';

import './Question.scss';

class Question extends React.Component {

    render() {

        return (
            <div className="question">
                {this.props.children}
            </div>
        );
    }
}

export default Question;
