import React from 'react';

import './Wrapper.scss';

const Wrapper = ({children}) => (
    <div className="wrapper">
        <div className="frame-one">
            {children[0]}
        </div>
        <div className="frame-two">
            {children[1]}
        </div>
    </div>
);

export default Wrapper;
