import React from 'react';

import './Link.scss';

const Link = ({ to, children }) => (
    <a href={to} target="_blank" rel="noopener noreferrer" className="link">
        {children}
    </a>
);

export default Link;
