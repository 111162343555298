import React from 'react';
import ReactDOM from 'react-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import Utils from './ui/Utils';
import App from './App';
// import registerServiceWorker from './registerServiceWorker';


function reducer(state, action) {
    function newState(valuePath) {
        const value = action.payload;
        return Utils.deepUpdate(state, valuePath, value);
    }

    function newStates(...pathsAndValues) {
        let updatedState = state;

        pathsAndValues.forEach(([valuePath, value]) => {
            updatedState = Utils.deepUpdate(updatedState, valuePath, value);
        });

        return updatedState;
    }

    switch (action.type) {
        case 'NAVIGATION_INITIALIZED':
            return newState('navigation.initialized');
        case 'NAVIGATION_SET_MEDIA':
            return newStates(
                ['navigation.media', action.payload.media],
                ['navigation.width', action.payload.width],
            );
        case 'NAVIGATION_SET_SCROLL':
            return newStates(
                ['navigation.position', action.payload.position],
                ['navigation.direction', action.payload.direction],
            );
        case 'LIBS_FIREBASE':
            return newState('libs.firebase');
        default:
            return state;
    }
}


function getApplicationState() {
    return {
        navigation: {
            initialized: false,
            width: window.innerWidth,
            media: Utils.getMediaFromWidth(window.innerWidth),
            position: 0,
            direction: 'down',
        },
        libs: {
            firebase: null,
        },
    };
}

window.garhammar = {};
window.garhammar.getParentByClass = function(el, cls) {
    while (el && !el.classList.contains(cls)) {
        el = el.parentElement;
    }
    return el;
};

const reduxStore = createStore(reducer, getApplicationState(), window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

ReactDOM.render(
    <Provider store={reduxStore}>
        <App />
    </Provider>,
    window.document.getElementById('root'),
    () => {

        function updateWidth() {
            const width = window.innerWidth;
            reduxStore.dispatch({
                type: 'NAVIGATION_SET_MEDIA',
                payload: {
                    media: Utils.getMediaFromWidth(width),
                    width,
                },
            });
        }

        window.addEventListener('resize', updateWidth);

        window.addEventListener('orientationchange', updateWidth);

        setTimeout(() => {

            reduxStore.dispatch({
                type: 'NAVIGATION_INITIALIZED',
                payload: true,
            });

        }, 80);


        let last_known_scroll_position = 0;
        let direction_position = 0;
        let ticking = false;
        let direction = 'down';

        window.addEventListener('scroll', function() {
            last_known_scroll_position = window.scrollY;
            if (!ticking) {
                if (last_known_scroll_position > direction_position) {
                    direction = 'down';
                } else {
                    direction = 'up';
                }
                direction_position = last_known_scroll_position;
                window.requestAnimationFrame(function() {
                    reduxStore.dispatch({
                        type: 'NAVIGATION_SET_SCROLL',
                        payload: {
                            position: last_known_scroll_position,
                            direction: direction,
                        }
                    });
                    ticking = false;
                });
            }
            ticking = true;
        });

    }
);
// registerServiceWorker();
