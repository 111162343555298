import React from "react";
import cx from "classnames";
import Button from "../../ui/buttons/Button";
import Icon from "../../ui/icons/Icon";
import Image from "../../ui/Image/Image";

import "./Work.scss";

const Work = ({ onMouseEnter, onMouseLeave, hovered, isWorkHovered }) => {
  function enterFn(name) {
    return (ev) => {
      onMouseEnter(name, ev);
    };
  }

  const classes = cx("works", isWorkHovered ? hovered : null);

  return (
    <div className={classes}>
      <ul>
        <li
          id="average"
          className="work-item"
          onMouseEnter={enterFn("work-average")}
          onMouseLeave={onMouseLeave}
        >
          <div className="work-wrapper">
            <div className="year">
              2020
              <Button to="https://average.productions" target="_blank">
                <Icon glyph="EXTERNAL" />
              </Button>
            </div>
            <div className="assignment">
              <h4>Average Productions</h4>
              <Image
                alt="Average productions"
                active={true}
                src="/images/splash.jpg"
                ratio={1200 / 630}
              />
            </div>
          </div>
        </li>

        <li
          id="lysa"
          className="work-item"
          onMouseEnter={enterFn("work-lysa")}
          onMouseLeave={onMouseLeave}
        >
          <div className="work-wrapper">
            <div className="year">
              2016
              <Button to="https://lysa.se" target="_blank">
                <Icon glyph="EXTERNAL" />
              </Button>
            </div>
            <div className="assignment">
              <h4>Lysa</h4>
              <p>Bygger världens bästa robotrådgivare.</p>
            </div>
          </div>
        </li>

        <li
          id="viaplay"
          className="work-item"
          onMouseEnter={enterFn("work-viaplay")}
          onMouseLeave={onMouseLeave}
        >
          <div className="work-wrapper">
            <div className="year">
              2015
              <Button to="https://viaplay.se" target="_blank">
                <Icon glyph="EXTERNAL" />
              </Button>
            </div>
            <div className="assignment">
              <h4>Viaplay</h4>
              <p>Utveckling av Viaplays streamingtjänst.</p>
            </div>
          </div>
        </li>

        <li
          id="dn"
          className="work-item"
          onMouseEnter={enterFn("work-dn")}
          onMouseLeave={onMouseLeave}
        >
          <div className="work-wrapper">
            <div className="year">
              2014
              <Button to="https://dn.se" target="_blank">
                <Icon glyph="EXTERNAL" />
              </Button>
            </div>
            <div className="assignment">
              <h4>Dagens Nyheter</h4>
              <p>
                Interaktivitet till berättandet - visualisera stora mängder
                data, implementera en överskådlig tidslinje eller rendera en
                zoombar karta.
              </p>
            </div>
          </div>
        </li>

        <li
          id="avanza"
          className="work-item"
          onMouseEnter={enterFn("work-avanza")}
          onMouseLeave={onMouseLeave}
        >
          <div className="work-wrapper">
            <div className="year">
              2010
              <Button to="https://avanza.se" target="_blank">
                <Icon glyph="EXTERNAL" />
              </Button>
            </div>
            <div className="assignment">
              <h4>Avanza</h4>
              <p>Implementerade kundcykeln.</p>
            </div>
          </div>
        </li>

        <li
          id="tactel"
          className="work-item"
          onMouseEnter={enterFn("work-tactel")}
          onMouseLeave={onMouseLeave}
        >
          <div className="work-wrapper">
            <div className="year">
              2005
              <Button to="https://tactel.se" target="_blank">
                <Icon glyph="EXTERNAL" />
              </Button>
            </div>
            <div className="assignment">
              <h4>Tactel</h4>
              <p>
                En rad olika tjänster, från testingenjör, via c-utveckling, via
                internutbildning, till java-utvecklare.
              </p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default Work;
