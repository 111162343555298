import React from 'react';
import Image from '../../ui/Image/Image';

import './Images.scss';

const ImagesContainer = ({active}) => (
    <div className="images-container">

        <div className="image-wrapper">
            <Image
                alt="Vänner"
                active={active}
                src="/images/suspects.jpg"
                ratio={800 / 600}
            />
            <div className="image-note">
                Usual suspects - <i>2015 kanske</i>
            </div>
        </div>

        <div className="image-wrapper">
            <Image
                alt="Fitz roy"
                active={active}
                src="/images/IMG_20160302_road_to_fitz.jpg"
                ratio={1000 / 740}
            />
            <div className="image-note">
                Fitz Roy - <i>Mars 2016</i>
            </div>
        </div>

        <div className="image-wrapper">
            <Image
                alt="Moreno-glaciären"
                active={active}
                src="/images/IMG_20160301_me_1.jpg"
                ratio={1000 / 740}
            />
            <div className="image-note">
                Moreno-glaciären - <i>Mars 2016</i>
            </div>
        </div>

        <div className="image-wrapper">
            <Image
                alt="Beagle-sundet"
                active={active}
                src="/images/IMG_20160224_plane_in_air.jpg"
                ratio={1000 / 740}
            />
            <div className="image-note">
                Beagle-sundet - <i>Februari 2016</i>
            </div>
        </div>

        <div className="image-wrapper">
            <Image
                alt="Patagonien"
                active={active}
                src="/images/IMG_20160219_road.jpg"
                ratio={1000 / 750}
            />
            <div className="image-note">
                Någonstans i Patagonien - <i>Februari 2016</i>
            </div>
        </div>

        <div className="image-wrapper">
            <Image
                alt="Rio"
                active={active}
                src="/images/IMG_20160129_rio.jpg"
                ratio={1000 / 740}
            />
            <div className="image-note">
                Sockertoppen Rio - <i>Februari 2016</i>
            </div>
        </div>

        <div className="image-wrapper">
            <Image
                alt="Copacabana"
                active={active}
                src="/images/IMG_20160128_copacabana.jpg"
                ratio={1000 / 750}
            />
            <div className="image-note">
                Copacabana Rio - <i>Februari 2016</i>
            </div>
        </div>

        <div className="image-wrapper">
            <Image
                alt="Kalmarsund"
                active={active}
                src="/images/IMG_20160121_boat.jpg"
                ratio={1000 / 750}
            />
            <div className="image-note">
                Kalmarsund - <i>Januari 2016</i>
            </div>
        </div>

        <div className="image-wrapper">
            <Image
                alt="Farsan med hundarna"
                active={active}
                src="/images/skog_2016_01_21.jpg"
                ratio={1000 / 740}
            />
            <div className="image-note">
                Farsan med hundarna - <i>Januari 2016</i>
            </div>
        </div>

        <div className="image-wrapper">
            <Image
                alt="Stockholms stadsbibliotek"
                active={active}
                src="/images/bibliotek.jpg"
                ratio={1000 / 750}
            />
            <div className="image-note">
                Stockholms stadsbibliotek (Gunnar Asplund) - <i>Januari 2016</i>
            </div>
        </div>


        <div className="split-image">
            <div className="image-wrapper">
                <Image
                    alt="Dublin"
                    active={active}
                    src="/images/gate.jpg"
                    ratio={600 / 800}
                />
                <div className="image-note">
                    Dublin - <i>Hösten 2015</i>
                </div>
            </div>

            <div className="image-wrapper">
                <Image
                    alt="Dublin med John"
                    active={active}
                    src="/images/john.jpg"
                    ratio={600 / 800}
                />
                <div className="image-note">
                    Dublin med John - <i>Hösten 2016</i>
                </div>
            </div>
        </div>

        <div className="split-image">

            <div className="image-wrapper">
                <Image
                    alt="Main man och syster"
                    active={active}
                    src="/images/tim_syster.jpg"
                    ratio={800 / 1067}
                />
                <div className="image-note">
                    Main main och syster - <i>Säg 2015</i>
                </div>
            </div>

            <div className="image-wrapper">
                <Image
                    alt="Main man och farsan"
                    active={active}
                    src="/images/pops_main_man.jpg"
                    ratio={800 / 1067}
                />
                <div className="image-note">
                    Main man och farsan - <i>2014 Give or take</i>
                </div>
            </div>

        </div>


        <div className="centered-image">
            <div className="image-wrapper">
                <Image
                    alt="Top dog"
                    active={active}
                    src="/images/main_man_toned.jpg"
                    ratio={800 / 1067}
                />
                <div className="image-note">
                    Top dog - <i>Datum okänt</i>
                </div>
            </div>
        </div>

    </div>
);

export default ImagesContainer;
