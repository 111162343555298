import React from 'react';

import './Section.scss';

const Section = ({ header, className, children }) => (
    <section className={className ? className + ' section' : 'section'}>
        <div className="section-header">
            <h2>{header}</h2>
            <div className="section-header-line-through" />
        </div>
        {children}
    </section>
);

export default Section;
