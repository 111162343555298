import React from 'react';
import cx from 'classnames';

import './Button.scss';

class Button extends React.Component {
    
    onClick = (event) => {
        const { onClick, payload } = this.props;
        onClick(event, payload);
    };

    render() {
        const { className, to, children } = this.props;

        const classes = cx('btn', className);

        if (to) {
          return (
                <a
                    href={to}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes}
                >
                    {children}
                </a>
            );
        }

        return (
            <button className={classes} onClick={this.onClick}>
                {children}
            </button>
        );
    }
}

export default Button;
