import React from 'react';
import { connect } from 'react-redux';
import firebase from '@firebase/app';
import '@firebase/database'
// import Utils from '../../ui/Utils';
import Tips from './Tips';

class TipsContainer extends React.Component {

    constructor() {
        super();
        this.state = {
            posts: [],
            name: '',
            message: '',
            fixed: false,
            toggled: localStorage.getItem('garhammar_tips') !== 'false',
        };
    }

    componentDidMount() {

        const config = {
            apiKey: "AIzaSyBQfuO6d_kRey4T4W3aiZuCnU4XV5NTkRw",
            authDomain: "garhammar-6cebd.firebaseapp.com",
            databaseURL: "https://garhammar-6cebd.firebaseio.com",
            projectId: "garhammar-6cebd",
            storageBucket: "garhammar-6cebd.appspot.com",
            messagingSenderId: "988991713450"
        };
        firebase.initializeApp(config);
        const ref = firebase.database().ref();
        let postsLoaded = false;
        this.props.storeFirebaseRef(ref);


        const dbPostsRef = ref.child('posts').orderByChild('order').limitToFirst(50);

        dbPostsRef.on('child_added', snap => {
            if (!postsLoaded) { return; }
            const clone = this.state.posts.slice();
            clone.unshift(snap.val());
            this.setState({
                posts: clone,
            });
        });

        dbPostsRef.once('value', snap => {
            postsLoaded = true;
            const posts = [];
            const values = snap.val();
            if (!values) { return; }
            Object.keys(values).forEach(key => {
                posts.push(values[key]);
            });
            posts.sort((a, b) => {
                if (a.date > b.date) {
                    return -1;
                }
                if (a.date < b.date) {
                    return 1;
                }
                return 0;
            })
            this.setState({ posts });
        });

        if ('IntersectionObserver' in window) {

            const onChange = (changes, observer) => {
                changes.forEach(change => {

                    if (change.target.classList.contains('table-header')) {
                        if (change.intersectionRatio === 0) {
                            observer.unobserve(this.tableHeader);
                            observer.observe(this.placeholder);
                            this.setState({
                                fixed: true,
                            });
                        }
                    } else {
                        if (change.intersectionRatio > 0) {
                            observer.unobserve(this.placeholder);
                            observer.observe(this.tableHeader);
                            this.setState({
                                fixed: false,
                            });
                        }
                    }


                });
            }

            const observer = new IntersectionObserver(onChange, {});
            observer.observe(this.tableHeader);
        }

    }

    onNameChange = event => {
        const { value } = event.target;
        this.setState({
            name: value,
        });
    }

    onMessageChange = event => {
        const { value } = event.target;
        this.setState({
            message: value,
        });
    }

    setRef = e => this.tableHeader = e;

    setPlaceholderRef = e => this.placeholder = e;

    onPost = (ev, post) => {
        ev.preventDefault();
        if (!this.props.firebase) { return; }
        const { name, message } = this.state;
        const dbPostsRef = this.props.firebase.child('posts');
        const newPostRef = dbPostsRef.push();
        const time = (new Date()).getTime();
        newPostRef.set({
            date: time,
            order: -time,
            name,
            message,
        });

        this.setState({
            name: '',
            message: '',
        })
    }

    toggle = ev => {
        ev.preventDefault();

        const toggled = !this.state.toggled;
        localStorage.setItem('garhammar_tips', toggled);

        this.setState({
            toggled,
        });
    }

    render() {
        return (
            <Tips
                {...this.state}
                onPost={this.onPost}
                onMessageChange={this.onMessageChange}
                onNameChange={this.onNameChange}
                setRef={this.setRef}
                setPlaceholderRef={this.setPlaceholderRef}
                toggle={this.toggle}
            />
        );
    }
}


const mapStateToProps = state => ({
    firebase: state.libs.firebase,
});

const mapDispatchToProps = dispatch => ({
    storeFirebaseRef: ref => dispatch({ type: 'LIBS_FIREBASE', payload: ref }),
});


export default connect(mapStateToProps, mapDispatchToProps)(TipsContainer);
