import React from 'react';
import cx from 'classnames';

import './Button.scss';

const Button = ({onClick, className, children, disabled}) => (
    <button className={cx('button', className, { disabled })} onClick={onClick}>
        {children}
    </button>
);

export default Button
